.my-nav-item:hover {
  color: white;
}
.sidebar .nav.sub-menu .nav-item .nav-link.active {
  color: #0047ab;
  font-weight: 600;
}
.nav-link {
  color: #000;
}
.sidebar .nav.sub-menu .nav-item .nav-link:hover {
  color: #0047ab;
}
