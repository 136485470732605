.guest-post-container {
    background-color: #f8f9fa;
    padding: 16px;
    border-radius: 8px;
  }
  
  .guest-post-header {
    font-weight: bold;
    color: #343a40;
  }
  
  .guest-post-register-btn {
    background-color: #007bff;
    border: none;
    color: white;
  }
  
  .guest-post-side-panel {
    position: fixed;
    top: 0;
    right: -100%;
    width: 30vw;
    height: 100%;
    background: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease-in-out;
    z-index: 1050;
  }
  
  .guest-post-side-panel.open {
    right: 0;
  }
  
  .guest-post-side-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19px;
    background-color: #343a40;
    color: white;
  }
  
  .guest-post-close-btn {
    font-size: 20px;
    color: white;
    cursor: pointer;
  }
  
  .guest-post-form {
    padding: 16px;
  }
  
  .guest-post-form-submit-btn {
    width: 100%;
    background-color: #007bff;
    color: white;
    border: none;
  }
  