.guestpost-container {
  font-family: Arial, sans-serif;
  padding: 7px;
  background-color: #f9f9f9;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.guestpost-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.guestpost-table th,
.guestpost-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.guestpost-table th {
  background: #B3C8CF;
  color: #1C325B;
  font-size: 0.8rem;
}

.guestpost-table td {
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .guestpost-table th,
  .guestpost-table td {
    font-size: 0.75rem;
    padding: 8px;
  }
}

@media (max-width: 576px) {
  .guestpost-table th,
  .guestpost-table td {
    font-size: 0.7rem;
    padding: 6px;
  }
}

  .guestpost-action-btn {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .guestpost-action-btn:hover {
    background-color: #0056b3;
  }
  
  .guestpost-sidebar {
    position: fixed;
    top: 4rem;
    right: 0;
    width: 25rem;
    height: 100%;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    animation: slide-in 0.3s ease-out;
  }
  
  @keyframes slide-in {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .guestpost-close-btn {
    background: none;
    border: none;
    color: #ff0000;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
 
.guestpost-messages {
    flex-grow: 1;
    margin-top: 20px;
    overflow-y: auto;
    padding-right: 10px;
    margin-bottom: 2.5rem;
  }
  
  .message-row {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  
  .user-message {
    align-items: flex-end;
  }
  
  .other-message {
    align-items: flex-start;
  }
  
  .message-row p {
    background-color: #e9ecef;
    border-radius: 10px;
    padding: 10px;
    max-width: 70%;
    font-size: 14px;
    margin: 0;
  }
  
  .user-message p {
    background-color: #007bff;
    color: white;
  }
  
  .message-timestamp {
    font-size: 12px;
    color: #666;
    margin-top: 5px;
  }
  .message-input-container {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-top: 1px solid #ccc;
    position: sticky;
    bottom: 0;
    background-color: white;
    z-index: 10; 
  }
  
  
  .message-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .send-button,
.clear-button {
  padding: 8px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  font-size: 16px;
}
  
.clear-button {
    background-color: #dc3545;
  }
  
  .send-button:hover {
    background-color: #0056b3;
  }
  
  .clear-button:hover {
    background-color: #c82333;
  }