@import url('https://unpkg.com/leaflet/dist/leaflet.css');


.bg-white-theme {
  background-color: #f5f5f5;
}
.nav-tabs .nav-link {
  background: #f5f5f5;
  color: black;
}
.nav-tabs .nav-link.active {
  color: #035fb3 !important;
  background-color: #fff !important;
  border-bottom: 2px solid #035fb3 !important;
}
.row {
  padding: 10px;
}
.border-neglect {
  border-width: 0px;
}
.tab-content {
  border-width: 0px;
  padding-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
}
.select-drop {
  border: 1px solid rgb(147, 147, 147);
  border-radius: 4px;
  padding: 4px;
}
.custom-btn {
  background: #fff;
  color: #007cb9;
  border: 2px solid #007cb9;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
}
.side-sheet {
  box-shadow: 2px 4px 8px #585858;
  padding: 0px;
}
.custom-grey-btn {
  background: #f2f2f2;
  color: #212121;
  border: 1px solid #c2c2c2;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: small;
}
.picker{
  color :#b2b2b3;
  color: #e0dede;
}
.custom-scroll-hide {
  overflow-y: scroll; 
}

.custom-scroll-hide::-webkit-scrollbar {
  width: 0px; 
  background: transparent; 
}

.custom-scroll-hide {
  scrollbar-width: none; 
}

.custom-scroll-hide {
  -ms-overflow-style: none; 
}

.jsonmodal .modal-content{
  width: 170% !important;
}
.page-url-column {
  width: 10% !important;
}

.user-agent-column {
  width: 10% !important;
}


.template-card {
  position: relative;
  overflow: hidden;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.template-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.feed-button-container {
  position: absolute; 
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0; 
  background-color: rgba(59, 130, 246, 0.8);
  color: white; 
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  opacity: 0; 
  transition: opacity 0.3s ease; 
}

.template-card:hover .feed-button-container {
  opacity: 1; 
}
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and other WebKit browsers */
}
.video-card {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  width: calc(25% - 20px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
}

.video-card img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.video-card:hover .get-details-btn {
  display: block; 
}

.get-details-btn {
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: none; 
}

.get-details-btn:hover {
  background-color: #0056b3;
}
.video-details {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

.video-details span {
  display: flex;
  align-items: center;
}

.video-details span svg {
  margin-right: 5px;
}
.table-container {
  max-height: 90vh; 
  overflow-y: auto; 
  overflow-x: hidden; 
  border: 1px solid #ddd; 
}

.schema-table {
  width: 100%;
  border-collapse: collapse;
}

.schema-table th,
.schema-table td {
  padding: 8px 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

/* .schema-table th {
  position: sticky; 
  top: 0;
  background-color: #f8f9fa; 
  z-index: 1; 
} */

.schema-image {
  max-width: 40vh;
  height: auto;
}
.schema-table td {
  vertical-align: top;
}

/* Styling for the vertical tabs */
.sitemap-tabs-container .nav-item {
  width: 100%; /* Ensure the tabs take the full width */
}

.sitemap-tabs-container .nav-link {
  padding: 10px 15px;
  text-align: center;
  font-weight: bold;
  border-radius: 0; /* Remove borders for a clean look */
}

.sitemap-tabs-container .nav-link.active {
  background-color: rgba(234,229,244) !important;
  color: white;
}

/* Styling for the content area */
.sitemap-content-container .tab-content {
  /* padding: 20px; */
  border-left: 2px solid #ddd;
  background-color: #f8f9fa; /* Light background for content */
}
.sitemap-content-container .tab-pane {
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.invoicecustom-modal .modal-content{
  width: 50vw !important;

}


.analytics-tabs {
  display: flex;
  flex-direction: column;
}

.analytic-buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.analytic-button {
  padding:5px 10px;
  border: none;
  background-color: #fff; 
  color: #000; 
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s;
  position: relative;
}

.analytic-button.active {
  border-top: 3px solid #007bff; 
  color: #007bff; 
}
.table-no-side-border {
  border-collapse: collapse;
}

.table-no-side-border thead th, 
.table-no-side-border tbody td, 
.table-no-side-border tfoot td {
  border-left: none ;
  border-right: none ;
}

.table-no-side-border thead th, 
.table-no-side-border tfoot td {
  border-bottom: 2px solid #dee2e6 !important;
}

.table-no-side-border tbody tr td {
  border-bottom: 1px solid #dee2e6 !important; 
}


.realtime-pages {
  background-color: #f8f9fa;
  padding: 20px;
}

.kpi-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  text-align: center;
  border-radius: 8px;
}

.kpi-card h5 {
  color: #6c757d;
  margin-bottom: 10px;
}

.kpi-card h3 {
  color: #007bff;
  font-size: 24px;
}

.realtime-table th {
  background-color: #007bff;
  color: #fff;
  text-align: center;
}

.realtime-table td {
  text-align: center;
}

.seo-roadmap-container {
  position: relative;
  overflow: hidden;
  animation: seo-roadmap-fadeIn 1s ease-in-out;
}

.seo-roadmap-title {
  color: #0f67b1;
  font-size: 1.5rem;
  animation: seo-roadmap-slideDown 1s ease;
}

.seo-roadmap-cards .seo-roadmap-card {
  border-radius: 10px;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  background: linear-gradient(135deg, #f9f9f9, #ffffff);
}

.seo-roadmap-card:hover {
  transform: scale(1.05) rotate(-2deg);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.seo-roadmap-card-animate {
  animation: seo-roadmap-zoomIn 0.7s ease;
}

.seo-roadmap-card-title {
  color: #441752;
  font-weight: bold;
  font-size: 1.1rem;
}

.seo-roadmap-card-content {
  font-size: 0.9rem;
  color: #441752;
}

@keyframes seo-roadmap-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes seo-roadmap-slideDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes seo-roadmap-zoomIn {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes uniqueFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes uniqueSlideUp {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes uniqueZoomIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes uniqueBounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.unique-fade-in {
  animation: uniqueFadeIn 1s ease-out forwards;
}

.unique-slide-up {
  animation: uniqueSlideUp 1s ease-out forwards;
}

.unique-zoom-in {
  animation: uniqueZoomIn 1s ease-out forwards;
}

.unique-bounce {
  animation: uniqueBounce 1s ease-in-out infinite;
}
