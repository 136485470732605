.card {
    position: relative; /* Set relative positioning for the card */
    overflow: hidden; /* Hide overflow for the gradient overlay */
  }
  
  .gradient-overlay {
    position: absolute;
    top: -100%; /* Start hidden above the card */
    left: 0;
    right: 0;
    height: 100%; /* Cover the entire height of the card */
    background: linear-gradient(to bottom, rgba(59, 130, 246, 1) 0%, rgba(59, 130, 246, 0.5) 100%); /* Darker blue to lighter blue */
    transition: top 0.5s ease; /* Faster transition */
  }
  
  .card:hover .gradient-overlay {
    top: 0; /* Move the overlay down to cover half the card on hover */
  }
  
  .icon-overlay {
    position: absolute;
    top: 20px; /* Position from the top of the card */
    left: 50%; /* Center the icons horizontally */
    transform: translateX(-50%); /* Center alignment correction */
    display: flex; /* Use flexbox to arrange icons */
    gap: 20px; /* Space between icons */
    opacity: 0; /* Start hidden */
    transition: opacity 0.5s ease; /* Fade-in transition */
  }
  
  .card:hover .icon-overlay {
    opacity: 1; /* Show icons on hover */
  }
  
  .icon {
    color: white; /* Change icon color */
    font-size: 24px; /* Icon size */
  }
  